
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        




































































































.progress {
  height: rem(38);
}
