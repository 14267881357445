
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        























































































:root {
  --ck-color-base-border: #{$input-border-color};
  --ck-border-radius: #{$border-radius};
  --ck-focus-ring: #{$border-width} solid #{$input-focus-border-color};
}

.ck {
  .is-invalid + & {
    .ck-toolbar,
    .ck-editor__editable_inline.ck-editor__editable_inline {
      border-color: $form-feedback-invalid-color;
    }
  }

  .ck-content {
    min-height: rem(240);
  }

  .ck-read-only.ck-read-only {
    background: $input-disabled-bg;
  }
}
